import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link as LinkM } from '@mui/material';
import { Container, Grid, IconButton, Stack } from '@mui/material';
import { Facebook, Instagram, LinkedIn, Twitter } from '@mui/icons-material';
import { Link } from 'react-router-dom';

function Copyright() {
  return (
    <Typography variant='subtitle2' color='white' sx={{ textAlign: 'center', fontWeight: 'bold' }}>
      {'Copyright © '}
      <LinkM color='inherit' to='/'>
        Laundry Basket Tdot Inc.
      </LinkM>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function StickyFooter(props) {
  return (
    <Box
      style={{
        paddingLeft: 0,
        paddingRight: 0,
      }}
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: 'auto',
        minHeight: '25vh',
        background: '#13263d',
      }}
    >
      <CssBaseline />
      <Container
        maxWidth='xlg'
        component='footer'
        sx={{
          pt: 3,
          pb: 2,
          backgroundColor: (theme) => (theme.palette.mode === 'light' ? '' : theme.palette.grey[800]),
        }}
      >
        <Grid container sx={{ borderBottomWidth: 1, borderBottomColor: 'gray', pb: 3 }}>
          <Grid item xs={12} sm={6} md={4.5} sx={{ pr: 10 }}>
            <img style={{ width: '22%' }} alt='logo' src={require('../assets/images/logo.png')} />
            <Typography sx={{ fontWeight: 'bold', color: 'white', pt: 1, pl: 1 }} variant='subtitle2'>
              Fresh Laundry, Hassle-Free! Free Pickup, Expert Cleaning & Fast Delivery – Because Your Time Matters!
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={2.5} sx={{ pt: { xs: 3, sm: 1, md: 1 } }}>
            <Typography sx={{ color: 'white', fontWeight: 'bold', pl: { xs: 1, sm: 0, md: 0 } }} component='h6' variant='h6'>
              Quick Links
            </Typography>
            <Link sx={{ display: 'block', width: 'fit-content', cursor: 'pointer' }} to='/'>
              <Typography
                sx={{ color: 'white', fontWeight: 'bold', pt: 1.5, pl: { xs: 1, sm: 0, md: 0 } }}
                component='p'
                variant='subtitle2'
              >
                Home
              </Typography>
            </Link>
            <Link sx={{ display: 'block', width: 'fit-content', cursor: 'pointer' }} to='/about'>
              <Typography
                sx={{ color: 'white', fontWeight: 'bold', pt: 1, pl: { xs: 1, sm: 0, md: 0 } }}
                component='p'
                variant='subtitle2'
              >
                About Us
              </Typography>
            </Link>
            <Link sx={{ display: 'block', width: 'fit-content', cursor: 'pointer' }} to='/services'>
              <Typography
                sx={{ color: 'white', fontWeight: 'bold', pt: 1, pl: { xs: 1, sm: 0, md: 0 } }}
                component='p'
                variant='subtitle2'
              >
                Services
              </Typography>
            </Link>
            <Link sx={{ display: 'block', width: 'fit-content', cursor: 'pointer' }} to='/pricing'>
              <Typography
                sx={{ color: 'white', fontWeight: 'bold', pt: 1, pl: { xs: 1, sm: 0, md: 0 } }}
                component='p'
                variant='subtitle2'
              >
                Pricing
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={6} sm={6} md={2.5} sx={{ pt: { xs: 3, sm: 3, md: 1 } }}>
            <Typography sx={{ color: 'white', fontWeight: 'bold', pl: { xs: 0, sm: 1, md: 0 } }} component='h6' variant='h6'>
              Company
            </Typography>
            <Link sx={{ display: 'block', width: 'fit-content', cursor: 'pointer' }} to='/terms-and-conditions'>
              <Typography
                sx={{ width: 'fit-content', color: 'white', fontWeight: 'bold', pt: 1.5, pl: { xs: 0, sm: 1, md: 0 } }}
                component='p'
                variant='subtitle2'
              >
                Terms & Conditions
              </Typography>
            </Link>
            <Link sx={{ display: 'block', width: 'fit-content', cursor: 'pointer' }} to='/privacy-policy'>
              <Typography
                sx={{ width: 'fit-content', color: 'white', fontWeight: 'bold', pt: 1.5, pl: { xs: 0, sm: 1, md: 0 } }}
                component='p'
                variant='subtitle2'
              >
                Privacy Policy
              </Typography>
            </Link>
            <Link sx={{ display: 'block', width: 'fit-content', cursor: 'pointer' }} to='/refund-policy'>
              <Typography
                sx={{ width: 'fit-content', color: 'white', fontWeight: 'bold', pt: 1.5, pl: { xs: 0, sm: 1, md: 0 } }}
                component='p'
                variant='subtitle2'
              >
                Refund Policy
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={6} sm={6} md={2.5} sx={{ pt: { xs: 3, sm: 3, md: 1 } }}>
            <Typography sx={{ color: 'white', pl: { xs: 1, sm: 0, md: 1 } }} component='h6' variant='h6'>
              Contact Us
            </Typography>
            <Typography
              sx={{ color: 'white', pt: 2, fontWeight: 'bold', pl: { xs: 1, sm: 0, md: 1 } }}
              component='p'
              variant='subtitle2'
            >
              <a href='tel:6472476745'>(647) 247-6745</a>
            </Typography>
            <Typography
              sx={{ color: 'white', fontWeight: 'bold', pt: 0.5, pl: { xs: 1, sm: 0, md: 1 } }}
              component='p'
              variant='subtitle2'
            >
              <a href='mailto:contact@laundrybasket.ca'>contact@laundrybasket.ca</a>
            </Typography>
            <Stack sx={{ pt: 2 }} direction='row' useFlexGap justifyContent='start' alignItems='start'>
              <IconButton>
                <Instagram sx={{ fontSize: 27, color: 'white' }}></Instagram>
              </IconButton>
              <IconButton>
                <Facebook sx={{ fontSize: 27, color: 'white' }}></Facebook>
              </IconButton>
              <IconButton>
                <Twitter sx={{ fontSize: 27, color: 'white' }}></Twitter>
              </IconButton>
              <IconButton>
                <LinkedIn sx={{ fontSize: 27, color: 'white' }}></LinkedIn>
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
        <Box sx={{ pt: 2 }}>
          <Copyright />
        </Box>
      </Container>
    </Box>
  );
}
