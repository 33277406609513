import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { createSearchParams, useNavigate } from 'react-router-dom';

library.add(faUser);

export default function Services(props) {
  const navigate = useNavigate();

  return (
    <Container
      maxWidth='xlg'
      sx={{
        pt: { xs: 4, sm: 6 },
        pb: { xs: 8, sm: 6 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component='h3' variant='h3' sx={{ color: 'text.secondary' }}>
          A wide range of{' '}
          <Typography variant='h3' sx={{ color: 'text.primary', display: 'inline' }}>
            Services
          </Typography>
        </Typography>
        <Typography variant='body1' sx={{ color: 'text.neutral', mt: 2 }}>
          Tired of spending hours at the laundromat or dealing with endless laundry piles? At Laundry Basket, we make laundry
          effortless with free pickup & delivery, ensuring fresh, clean clothes without the hassle.
        </Typography>
      </Box>
      {props.servicesLoaded ? (
        <Grid
          container
          spacing={{ xs: 2, sm: 5, md: 5 }}
          sx={{
            justifyContent: 'center',
            px: { xs: 0, sm: 2, md: 5 },
          }}
        >
          {props.services.map((service, index) => (
            <Grid sx={{ height: 'auto' }} item key={service.id} xs={6} sm={6} md={4}>
              <div
                style={{ cursor: 'pointer', height: '100%' }}
                onClick={() =>
                  navigate({
                    pathname: '/pricing',
                    search: `?${createSearchParams({
                      selectedIndex: index,
                    })}`,
                  })
                }
              >
                <Card
                  sx={[
                    {
                      py: { xs: 0, sm: 2, md: 2 },
                      px: { xs: 0, sm: 2, md: 2 },
                      display: 'flex',
                      height: '100%',
                      flexDirection: 'column',
                      gap: 4,
                    },
                    (theme) => ({
                      border: 'none',
                      boxShadow: `0 0 12px hsla(210, 98%, 42%, 0.2)`,
                      ...theme.applyStyles('dark', {
                        boxShadow: `0 0 12px hsla(0, 0%, 0%, 0.8)`,
                      }),
                    }),
                  ]}
                >
                  <CardContent>
                    <Box
                      sx={[
                        {
                          textAlign: 'center',
                          justifyItems: 'center',
                          gap: 2,
                        },
                      ]}
                    >
                      <Box
                        sx={{
                          mt: 2,
                          width: { xs: '90%', sm: '50%', md: '50%' },
                        }}
                      >
                        <img
                          style={{
                            width: '100%',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                          }}
                          src={service.pic3}
                          alt='new'
                        />
                      </Box>
                      <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}>
                        <Typography sx={{ mt: 2, color: 'text.secondary' }} component='h5' variant='h5'>
                          {service.name}
                        </Typography>
                        <Typography variant='body1' sx={{ color: 'text.neutral', mt: 2 }}>
                          {service.description}
                        </Typography>
                      </Box>
                      <Box sx={{ display: { xs: 'block', sm: 'none', md: 'none' } }}>
                        <Typography sx={{ mt: 2, color: 'text.secondary' }} component='h6' variant='h6'>
                          {service.name}
                        </Typography>
                        <Typography
                          variant='body1'
                          sx={{
                            color: 'text.neutral',
                            mt: 2,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '3',
                            WebkitBoxOrient: 'vertical',
                          }}
                        >
                          {service.description}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </div>
            </Grid>
          ))}
        </Grid>
      ) : null}
    </Container>
  );
}
